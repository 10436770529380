@mixin generate-breakpoint-css($breakPoint, $flexWidths, $flexLayoutGaps, $flexLayoutTypes, $flexLayoutAlignTypes) {

    [fxFlex_#{$breakPoint}] {
        flex: 1 1 0%;
        box-sizing: border-box;
        flex-direction: row;
        display: flex;
    }           
    
    [fxLayoutAlign_#{$breakPoint}] {
        display: flex;
        box-sizing: border-box;
    }

     // fxFlex="XX"
    @each $flexWidth in $flexWidths {
        @if (type-of($flexWidth) == 'number') {
            [fxFlex_#{$breakPoint}="#{$flexWidth}"] {
                flex: 1 1 100%;
                max-width: $flexWidth *1%;
            }
        } @else {
            [fxFlex_#{$breakPoint}="#{$flexWidth}"] {
                flex: 1 1 100%;
                max-width: $flexWidth;
            }
        }
    } 
    // fxLayoutGap="XX"
    @each $flexLayoutGap in $flexLayoutGaps {
        [fxLayoutGap_#{$breakPoint}="#{$flexLayoutGap}"] {
            gap:$flexLayoutGap;
        }
    }  
    // fxLayout="XX"
    @each $flexDirection, $flexWrap in $flexLayoutTypes {
        @if ($flexWrap) {
            [fxLayout_#{$breakPoint}="#{$flexDirection} #{$flexWrap}"] {
                display: flex;
                flex-wrap: $flexWrap;
                flex-direction: $flexDirection !important;
            }
        } @else {
            [fxLayout_#{$breakPoint}="#{$flexDirection}"] {
                display: flex;
                flex-direction: $flexDirection !important;
            }
        }
    }

    //fxLayoutAlign=XX
    @each $justifyContent, $alignItems in $flexLayoutAlignTypes {
        @if ($alignItems) { 
            [fxLayoutAlign_#{$breakPoint}="#{$justifyContent} #{$alignItems}"] {
                place-content: $alignItems $justifyContent;
                align-items: $alignItems;
            }            
        } @else {
            [fxLayoutAlign_#{$breakPoint}="#{$justifyContent}"] {
                place-content: $justifyContent $justifyContent;
                align-items: $justifyContent;
            }            
        }
    }

    // fxHide
    [fxHide_#{$breakPoint}] {
        display: none;
    }  
    // fxShow
    [fxShow_#{$breakPoint}] {
        display: flex;
    }
    // fxFill
    [fxFlexFill_#{$breakPoint}], [fxFill_#{$breakPoint}] {
        height: 100%;
        min-height: 100%;
        min-width: 100%;
        width: 100%;
        display: flex;
    }
}

