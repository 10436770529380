@import '~jsoneditor/dist/jsoneditor.min.css';
/* @import "~@ng-select/ng-select/themes/material.theme.css"; */
// @import "~@ng-select/ng-select/themes/default.theme.css";
@import './styles/flex-layout/flex-layout.scss';

html,
body {
  height: 100%;
}

body {
  font: 12px/16px Roboto, Arial;
  color: #565f5f;
// max-width:100%;
// width: fit-content;
//  min-width: 320px;
  // background: #e4e4e4 url(/src/assets/images/bg-body.png);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
}

.material-icons {
  font-family: 'Material Icons' !important;
}

/* .page {
  margin-top: 25px !important;
} */
/* .uxlib-btn { // UXL

  border-radius: 2px!important;
  min-width: 100px!important;
}

footer .mat-expansion-panel-header .mat-expansion-panel-header-title{ // UXL
  color: black !important;
}
footer .footer-top a{ // UXL
  color:black !important;
}
footer .link-container .mat-expansion-panel .mat-expansion-panel-content{// UXL
  font: unset !important;
}

uxlib-login-page-banner .sperator-v{ // UXL
  max-width: 50% !important;
}
 .footer-top .branding {// UXL
font: unset !important;
}
.mat-card-content {// UXL
  // font-size: 12px !important;
}
 */
/*** Utility Classes ***/
// forget password model css changes  

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: var(--primary-color)!important;
}

.mat-mdc-card.mat-card.mat-focus-indicator{
  box-shadow: none !important;
}

//  forget password model css changes 

/*.circle {
  width: 50px;
  height: 50px;
  background: #D8D8D8;
  background-color: #D8D8D8;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px #979797;
}

.rounded-square {
  width: 50px;
  height: 50px;
  background: #D8D8D8;
  background-color: #D8D8D8;
  border-radius: 5%;
  box-shadow: inset 0 0 0 1px #979797;
}

.circle2 {
  width: 50px;
  height: 50px;
  background: #D8D8D8;
  background-color: #D8D8D8;
  border-radius: 50%;
}

.no-box-shadow {
  box-shadow: none !important;
}*/

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: black !important;
}

/*.bg-tansparent {
  background: transparent;
}

.semi-transparent {
  opacity: 0.4;
}

.lightgreen-palette {
  color: #FFFFFF;
  background-color: #4EBA70;
}

.deepgreen-palette {
  color: #FFFFFF;
  background-color: #144444;
}

.navyblue-palette {
  color: #FFFFFF;
  background-color: #007A7A;
}

.lightblue-palette {
  color: #FFFFFF;
  background-color: #009E9E;
}

.green-palette {
  color: #FFFFFF;
  background-color: #004343;
}

.divider-x {
  border-bottom: 3px solid #F2F3F6;
}

.divider-y {
  border-right: 3px solid #F2F3F6;
}

.winter-palette {
  color: #FFFFFF;
  background-color: #848ea5;
}

.spring-palette {
  color: #FFFFFF;
  background-color: #7bcbc6;
}

.summer-palette {
  color: #FFFFFF;
  background-color: #60a404;
}

.fall-palette {
  color: #FFFFFF;
  background-color: #f0ac2c;
}*/

.icon-switch-account {
  background: url("/assets/images/app/icon-switch-account.png") no-repeat center;
  background-size: 32px 32px;
}

.fxFill {
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  display: flex;
}

/*.flex-w-100 {
  flex: 1 1 100%;
  display: flex;
  max-width: 100%;
}

.my-custom-snackbar {
  margin: 0 !important;
  position: absolute;
  right: 523px;
  top: 7px;
}

.blue-link-button {
  color: #35a0d1 !important;
  // margin-left: 3px;
  cursor: pointer;
  vertical-align: middle;
  padding: 5px;
  text-align: left;
  background: #d8ecf6;
  border-radius: 2.5px;
  border: 1px solid #d8ecf6;
}

.blue-link-button a span {
  vertical-align: top;
}

.blue-link-button-text {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

.blue-button {
  background-color: #257AA2 !important;*/
  /* #35a0d1 */
  // style change for aoda
  /*color: #fff;
}

.blue-button:disabled {
  color: #fff !important;
  background-color: #EFEFEF !important;
}*/

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

/*.footer-links-item.collapse.show{
  display: flex !important;
}
.footer-links-item.collapse{
  display: flex;
}

@media only screen and (max-width: 767px){
  .footer-links-item.collapse{
    display: none !important;
  }
}

.img-fluid {
  width: 45px;
  height: 45px;
}

.icon-power-outage {
  background: url('/assets/images/icon_power_outage.png') no-repeat center;
  background-size: contain;
}

.icon-bill-details {
  background: url('/assets/images/icon_bill_breakdown.png') no-repeat center;
  background-size: contain;
  border-radius: 0% !important;
}

.icon-bill-summary {
  background: url('/assets/images/bill-summary-2.png') no-repeat center;
  background-size: contain;
  border-radius: 0% !important;
}
.icon-electricity-usage {
    background: url('/assets/images/energy_usage_black.png') no-repeat center;
    background-size: contain !important;
}
.icon-water-usage {
    background: url('/assets/images/water_usage_black.png') no-repeat center;
    background-size: contain !important;
}
.icon-faq {
    background: url('/assets/images/icon-faq-charcoal.png') no-repeat center;
    background-size: contain !important;
}*/

/* ----------Account details stylesss start------------*/

/* You can add global styles to this file, and also import other style files */

/* html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */


$header-color:#d7d8da;
$bg-color:#f0f0f0;
$bg-electric:#eed6d7;
$bg-water:#d5edf8;

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card-header {
  // background-color: var(--secondary-color) !important;
  background-color: var(--primary-color) !important;
  color: #FFFFFF;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card-footer {
  background-color: $header-color;
}

/*::ng-deep .bgc {
  background-color: $bg-color;
}

::ng-deep .bgc-electric {
  background-color: $bg-electric !important;
}

::ng-deep .bgc-water {
  background-color: $bg-water !important;
}*/

textarea {
  display: block;
  width: 100% !important;
  height: 102px;
  resize: none;
  border: 1px solid #dedfe1;
  border-radius: 4px;
  margin: 0 0 21px;
  padding: 5px 0 0 5px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}


/* style for popup */

/*.form-content {
  font-weight: normal;
  //font-size: 13px;
  line-height: 16px;
  padding: 10px 0 0;
  font-weight: bold;
  color: #373b46;
}


.password-content {
  padding: 20px 20px 4px;
}*/

.required {
  display: block;
  text-align: right;
  margin: 0;
}

/*.password-content .required+.row-section,
.security-content .required+.row-section,
.email-content .required+.row-section {
  border: 0;
}

.password-content .form label,
.security-content .form label {
  float: left;
  width: 156px;
  margin: 11px 0 0;
}

.password-content .input-holder,
.security-content .input-holder {
  float: left;
  width: 312px;
}*/


p {
  font-weight: normal;
}


/* style for popup */

.uppcase {
  text-transform: uppercase;
}

/*.service-header {
  background-color: #f0f0f0;
}

.add {
  background-image: url("/assets/images/add-sign.png");
  width: 32px;
  height: 32px;
}

.ico-01 {
  background-image: url("/assets/images/ico-01.png");
  width: 32px;
  height: 32px;
}*/

/* for backround start */
/*.bg {
  margin: 0;
  font: 12px/16px Roboto, Arial;
  color: #565f5f;
  min-width: 320px;
  /* background:#e4e4e4 url(/src/assets/images/bg-body.png); */
  /*-webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
}*/

/* for bacground end */

a:hover {
  text-decoration: underline !important;
}

/*.path-list{
	  column-count: 4 !important;
    width: fit-content !important;
    max-width: fit-content !important;
}*/
/* added for billing breakdown  */
/*.col-value-1 {
  flex: 0 0 100px;
}

.col-value-1 span {
  width: 100%;
  text-align: right;
}*/

.mat-mdc-row:hover:not(.ng-trigger-detailExpand) {
  background-color: #fafafa;
}

.form-label{
	font-size: 14px;
  line-height: 28px;
	font-weight: bold;
  color: #373b46;
}

/*.asterix{
    color: red;
}*/

input.mat-mdc-input-element {
	font-size: 12px !important;
	font-weight: bold !important;
  position: inherit;
  top: 8px;
}
// UXL
// .mat-card-title{  
//   color: #4B5353;
//   font-size: 15px;
//   line-height: 20px;
//   font-weight: bold;
// }

/* slider */
/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
.mat-accent .mat-slider-track-fill, .mat-accent .mat-slider-thumb, .mat-accent .mat-slider-thumb-label {
    background-color: #ffd740;
}

 /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
 .mat-accent .mat-slider-thumb-label-text {
    color: #000000;
}

/* mat-slider  */
  /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
  mat-slider {
  width: 98%;
}

/* ngx switch style */

/*.switch-medium {
  width: 65px !important;
  height: 30px !important;
  border-radius: 6px !important;
}

.switch-medium.checked small {
  left: 35px !important;
}

.switch-medium small {
  width: 29px !important;
  height: 30px !important;
}*/

small {
    border-radius: 12% !important;
   }

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. -- DONE*/
.mdc-line-ripple{
  position: static !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. -- DONE*/
.mat-mdc-form-field-subscript-wrapper{
  position: static !important;
}


/* .app-header {
  background: #ffffff !important;
  border-bottom: 0 !important;
  box-shadow: 0 0 0 0 #0003 !important;
  padding: 10px 10px 20px 10px;
  font-family: Raleway, sans-serif !important;
  margin-bottom: 20px;
} */

/*.child--featured {
  display: none !important;
}*/

.ux-side-nav {
  background-color: #f5f5f5ff !important;
  color: #434343 !important;
  max-width: 275px !important;
}

.ux-group-label {
  text-transform: uppercase;
  color: #434343;
  font-weight: bold;
  font-size: 14px;
}

.ux-group-value {
  text-transform: uppercase;
  color: #434343;
  font-size: 12px;
}

.bg-red {
  background-color: var(--primary-color);
}

.mat-expansion-panel-header.bg-red:hover {
  background-color: var(--primary-color) !important;
  opacity: 0.8;
}

.mat-expansion-panel-header.bg-red:focus {
  background-color: var(--primary-color) !important;
}

/*.heading {
  text-transform: capitalize;
  font-weight: bold;
}*/

.mat-mdc-form-field-error{
  color:#E91B0C;
}

/*.loading-text {
  color: #999999;
  text-align: right;
  font-weight: bold;
  font-style: italic;
  font-size: 11px;
}

.blinking {
  animation: blinkingText 0.8s infinite;
}

.SUBMITTED {*/
  /* color: #42ab55 !important; */
/*}

.QUEUED {
  color: #8D6507 !important;
}

.CIS {
  color: #5C3CAF !important;
}

.EXCEPTION {
  color: #980000 !important;
}

.BLANKET {
  color: #00767A !important;
}

.DRAFT {
  color: #bbbbbb !important;
}

.COMPLETED {
  color: #008000 !important;
}

.BILLING {
  color: #1155cc !important;
}

.severity-H {
  color: #dc3545!important;
}

.severity-M {
  color: #ffc107!important;
}

.severity-L {
  color: #17a2b8!important;
}

.severity-S {
  color: #198754!important;
}

.severity-F {
  color: #dc3545!important;
}

.bg-severity-H {
  color: #dc3545!important;
}

.bg-severity-M {
  color: #ffc107!important;
}

.bg-severity-L {
  color: #17a2b8!important;
}

.bg-severity-S {
  color: #198754!important;
}

.bg-severity-F {
  color: #dc3545!important;
}*/

.burger-menu-open-png {
  background: url('assets/images/burger-menu-open.png');
  height: 2.5rem;
  width: 2.5rem;
  background-size: cover;
}

.burger-menu-close-png {
  background: url('assets/images/burger-menu-close.png');
  height: 2.5rem;
  width: 2.5rem;
  background-size: cover;
}

// .genesys-mxg-frame {
//   margin-right: -17px !important;
//   margin-bottom: -15px !important;
// }

uxlib-navigation-panel .layout-header,
.csr-home-page-responsive-container app-customer-lookup-form,
.csr-home-page-responsive-container app-pending-registration,
.csr-home-page-responsive-container app-service-request-cis-dashboard,
.csr-home-page-responsive-container app-user-search-form,
.csr-home-page-responsive-container app-audit-form,
.csr-home-page-responsive-container app-service-request-dashboard,
.banner-container .banner-content {
  max-width: 1440px !important;
  width: 100% !important;
}

.search-container .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
.search-container .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control{
  color: #373B46 !important;
  font-family: "Open Sans" !important;
  font-size: 30px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}

mat-cell.audit, mat-header-cell.audit, mat-footer-cell.audit {
  padding: 0 0.75rem 0 0.25rem;
}

app-pending-registration .mat-mdc-table .sl-index,
app-service-request-cis-dashboard .mat-mdc-table .sl-index {
  max-width: 4rem;
}

 @media only screen and (max-width: 959.98px) {

   app-pending-registration .mat-mdc-table .sl-index,
   app-service-request-cis-dashboard .mat-mdc-table .sl-index {
     max-width: none !important;
   }
 }

app-pending-registration .mat-mdc-table mat-checkbox.mat-mdc-checkbox,
app-service-request-cis-dashboard .mat-mdc-table mat-checkbox.mat-mdc-checkbox {
  --mdc-checkbox-unselected-focus-state-layer-color: transparent !important;
  --mdc-checkbox-unselected-pressed-state-layer-color: transparent !important;
  --mdc-checkbox-unselected-hover-state-layer-color: transparent !important;
  --mdc-checkbox-selected-focus-state-layer-color: transparent !important;
  --mdc-checkbox-selected-pressed-state-layer-color: transparent !important;
}

app-pending-registration .mat-mdc-table .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background
app-service-request-cis-dashboard .mat-mdc-table .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
app-service-request-cis-dashboard .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

.mat-mdc-paginator-page-size-select .mat-mdc-form-field-flex,
app-audit-form .mat-form-field-appearance-outline .mat-mdc-form-field-flex,
app-user-search-form .mat-form-field-appearance-outline .mat-mdc-form-field-flex,
app-service-request-cis-dashboard .mat-form-field-appearance-outline .mat-mdc-form-field-flex {
  position: static !important;
  margin-top: 0 !important;
  padding: 0 !important;
}

app-audit-form .mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mdc-notched-outline__notch,
app-user-search-form .mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mdc-notched-outline__notch,
app-service-request-cis-dashboard .mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mdc-notched-outline__notch {
  border-left: none !important;
}

app-audit-form .mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mdc-notched-outline__notch label,
app-user-search-form .mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mdc-notched-outline__notch label,
app-service-request-cis-dashboard .mat-mdc-form-field .mat-mdc-text-field-wrapper.mdc-text-field .mdc-notched-outline__notch label {
  margin: 0 !important;
  height: 100% !important;
}

app-audit-form .mat-mdc-form-field .mat-mdc-select,
app-service-request-cis-dashboard .mat-mdc-form-field .mat-mdc-select {
  display: flex;
  height: 2.5rem;
}

app-audit-form .mat-mdc-form-field-subscript-wrapper,
app-user-search-form .mat-mdc-form-field-subscript-wrapper,
app-service-request-cis-dashboard .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-mdc-form-field .mat-mdc-icon-button.mat-mdc-button-base {
  bottom: 0 !important;
}

app-service-request-cis-dashboard .mat-mdc-text-field-wrapper,
app-user-search-form .mat-mdc-text-field-wrapper {
  max-height: 43px !important;
}

.mobile-header-cell {
  display: none;
}

.cell-container {
  display: inline-block !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.check-svg {
  background: url('assets/images/check.svg');
  height: 1.875rem;
  width: 1.875rem;
  background-size: cover;
}

.down-arrow-svg {
  background: url('assets/images/down-chevron-black.svg');
  height: 1.5rem;
  width: 1.5rem;
  background-size: cover;
}

.inline-block {
  display: inline-block;
}

/*app-customer-look-form-v2 .mat-mdc-card-actions .search-btn {
  border-radius: 1rem;
  height: 3.375rem;
  font-family: "Open Sans";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  padding: 1rem 1.5rem;
}*/
/* customer lookup end */

/* floating menu changes start */
.floating-myaccount-mat-menu .mat-mdc-menu-content .mat-mdc-menu-item.active .mat-mdc-menu-item-text {
  background: var(--menu-item-background-color);
  margin-left: 0.438rem;
  padding-left: 0.624rem;
}

.floating-myaccount-mat-menu .mat-mdc-menu-content .mat-mdc-menu-item.active {
  border-left: 0.438rem solid var(--primary-color)
}
/* floating menu changes end */

.custom-mat-card-forms .form-label {

  color: var(--quinary-color);
  font-family: "Open Sans";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
}

.custom-mat-card-forms .disabled {
  color: #A1A1A1 !important;
}

.custom-mat-card-forms .disabled-bg .mdc-text-field--disabled {
  background: #E6E6E6 !important;
}

.custom-mat-card-forms mat-card-title {
  color: var(--quinary-color);
  font-family: "Open Sans";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.25rem;
}

.custom-mat-card-forms .mat-slider-box {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.custom-mat-card-forms .form-sub-label-ht {
  max-height: 3.5rem
}

.custom-mat-card-forms .mat-mdc-card-content {
  padding: 0.75rem 0.5625rem 0rem !important;
}

.custom-mat-card-forms .mat-divider {
  margin: 1.5rem -1.5rem;
  border-top-color: #D2D2D2 !important;
}

.custom-mat-card-forms uxlib-field-error {
  font-size: 0.75rem !important;
  word-break: break-word;
}

uxlib-home-page-layout uxlib-header,
uxlib-home-page-layout-v2 uxlib-header {
  display: none !important;
}

input:-webkit-autofill,
  input:-webkit-autofill:focus {
   transition: background-color 0s 600000s, color 0s 600000s !important;
}

.mat-expansion-indicator {
  width: 3.125rem;
  height: 3.125rem;
  text-align: center;
  border-radius: 50%;
  border: 0.0625rem solid var(--tertiary-color);
}

.mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator::after {
  padding: 0.375rem !important;
  color: var(--tertiary-color) !important;
  margin-left: 0 !important;
  border-width: 0 2px 2px 0 !important;
}

.mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
  padding: 1.375rem 2rem !important;
  color: #fff !important;
  font-family: "Open Sans" !important;
  font-size: 1.375rem !important;
  font-weight: 400 !important;
  line-height: 2.25rem !important;
  height: 4rem !important;
  border-radius: 0 !important;
}

.mat-accordion .mat-expansion-panel {
  border-radius: 1rem !important;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.16) !important;
}

a.mat-mdc-menu-trigger.nav-menu {
  cursor: pointer;
}