/*** Material Override ***/
.mat-app-background {
  background-color: #f7f9fa !important;
}

.mat-drawer-container {
  background-color: transparent !important;
}

.mat-mdc-tab-group {
  min-height: 50px;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label {
  width: 33.33%;
  opacity: 1 !important;
  color: #757575;
  /* #35a0d1;  #7a7a7a #fff*/
  font-size: 16px;
  text-transform: uppercase !important;
  background: #ffffff;
  /* #363843 */
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label:hover {
  background: #1B7C9D;
  /* background:  #004343; */
  color: #fff !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-disabled {
  opacity: 0.6 !important;
  cursor: not-allowed;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-disabled:hover {
  opacity: 0.6 !important;
  cursor: not-allowed;
}

.mat-ink-bar {
  background-color: #1B7C9D !important;
  opacity: 0.1 !important;
}

.mat-ink-bar:hover {
  background-color: #1B7C9D !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label-active {
  font-weight: bold !important;
  color: #000000de !important;
  background: #f1be48ff !important;
  opacity: 1 !important;
}

.mat-mdc-tab-link {
  font-weight: bold;
  font-size: 15px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: #eeeeeeff;
  color: #999999;
}

.mat-mdc-tab-link:hover {
  background: #f1be48ff !important;
  color: #434343ff !important;
  opacity: 0.7 !important;
}

::ng-deep .mat-ink-bar {
  background-color: #35a0d1 !important;
}


/*** mat-chip ***/
.mat-mdc-chip {
  font-size: 13px !important;
}

.mat-mdc-standard-chip {
  border-radius: 4px !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
mat-mdc-standard-chip {
  height: unset !important;
  font-weight: bold !important;
}
.mat-chip-list-stacked .mat-chip-list-wrapper .mat-standard-chip {
    width: 100%;
}

/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
mat-chip.ux-chip:not(.mdc-chip-selected) {
  background-color: none !important;
  color: #f5f5f5 !important;
  width: 267px !important;
  max-width: 275px !important;
}


/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
mat-chip.ux-chip.mdc-chip-selected {
  background-color: #434343ff !important;
  color: #FFFFFF !important;
}

.mat-mdc-standard-chip .mdc-evolution-chip__action--primary {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.mat-mdc-chip-list-stacked .mat-mdc-chip-list-wrapper .mat-standard-chip {
    width: 100%;
}


/*** ***/
.default-sidenav mat-sidenav {
  width: 20%;
}

/*** ***/
/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radio-container {
  width: 15px !important;
  height: 15px !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. --DONE*/
.mdc-label {
  margin-top: 4px;
}


/*** Mat Table ***/
.mat-mdc-table {
  width: 100%;
}

mat-header-row {
  background-color: var(--primary-color) !important;
  color: #FFFFFF !important;
}

mat-row:nth-child(even) {
  background-color: #f5f5f5ff;
}

mat-row:nth-child(odd) {
  background-color: #ffffff;
}

.mat-mdc-header-cell {
  color: #FFFFFF !important;
}

mat-cell, mat-header-cell {
  font-size: 12px !important;
}

mat-cell button {
  font-size: 12px !important;
}

mat-cell:first-of-type, mat-header-cell:first-of-type, mat-footer-cell:first-of-type {
  padding-left: 10px !important;
}

mat-cell:last-of-type, mat-header-cell:last-of-type, mat-footer-cell:last-of-type {
  padding-right: 10px !important;
}

mat-cell.sl, mat-header-cell.sl, mat-footer-cell.sl {
    max-width: 40px;
}

/* mat-row, mat-footer-row {
  height: 40px;
} */

mat-row:hover {
  background-color: #cecece !important;
  // color: #bbbbbb !important;
}

td.mat-mdc-footer-cell {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(0,0,0,.12);
}


.mat-mdc-tooltip {
  cursor: pointer;
  font-size: 14px !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
.mat-card-header-text {
  margin: 0 10px !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. -- DONE*/
.mat-mdc-radio-button .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle,
.mat-mdc-radio-button .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: #007bff !important;
}

.mat-mdc-radio-button.mat-accent .mdc-radio__inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #007bff !important;
}

/* .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 6px 0 13px 0 !important;
  width: 500px;
} */

.mat-body {
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

/*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
.mat-slider-thumb-label {
  transform: rotate(45deg) !important;
  border-radius: 50% 50% 0 !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
.mat-slider-thumb {
  transform: scale(0) !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
.mat-slider-thumb-label-text {
  opacity: 1 !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-inner-container {
  height: 20px !important;
  width: 20px !important;
  margin-right: 8px !important;
  margin-top: auto;
}

.mat-sort-header-arrow {
  color: #fff !important;
}

.mat-expansion-panel-header,
.mat-expansion-panel-header.mat-expanded {
  height: 36px !important;
  font-size: 14px !important;
}

.mat-expansion-indicator::after {
  border-width: 0 4px 4px 0 !important;
  color: #FFFFFF !important;
  padding: 4px !important;
  margin-top: -4px;
  margin-left: 4px;
}

// .mat-expansion-panel-body {
//     padding: 0 10px 10px !important;
// }

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. -- DONE*/
.mat-mdc-text-field-wrapper {  
    padding-bottom: 0.25em !important;
}

.mat-expansion-panel-body {
    padding: 0 10px 16px !important;
}

/* generic fixes related to mdc  */
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #ffffff !important;
}
.mat-mdc-form-field-focus-overlay {
  background-color: #ffffff !important;
}
.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #ffffff !important;
}
.mat-mdc-form-field {
  font-size: inherit !important;
  font-weight: 400 !important;
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
  letter-spacing: normal !important;
}

.mdc-text-field--filled .mdc-floating-label {
  font-size: 14px !important;
  padding-bottom: 5px !important;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  bottom: 5px !important;
}

.mat-mdc-text-field-wrapper {
  max-height: 40px !important;
  color: rgba(0, 0, 0, .12) !important;
}

.mat-mdc-form-field-infix {
  padding: 0.75px !important;
}

.mdc-text-field:not(.mdc-text-field--invalid):not(.mdc-text-field--focused):not(.mdc-text-field--disabled) {
  .mdc-notched-outline > * {
    border-color: rgba(0,0,0, 0.2) !important;
  }
}

.mdc-text-field:not(.mdc-text-field--invalid).mdc-text-field--focused {
  .mdc-notched-outline > * {
    border-color: #434343 !important;
  }
}

.mat-mdc-card {
  padding: 16px;
}

.mat-form-field-appearance-outline .mat-mdc-form-field-flex {
  padding: 0 .75em 0 .75em;
  margin-top: -0.25em;
  position: relative;
  height: 43px;
  top: 13px;
}

.mat-mdc-paginator .mat-mdc-select {
    line-height: 2 !important;
}

mat-icon[matsuffix] {
  color:#434343;
}

button[matsuffix]:hover {
  --mat-icon-button-state-layer-color: transparent;
}

.mdc-text-field--focused {
  border-color: #434343;
}
