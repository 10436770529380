// fxFlex="XX" 
$flexWidths: 1, 2, 5, 6, 10, 12, 14, 15, 16, 17, 20, 23, 24, 24.5, 25, 28, 30, 33, 35, 40, 45,
 48, 49, 50, 55, 60, 65, 70, 75, 77, 80, 85, 90, 96, 100;

// fxLayoutGap="XX"
$flexLayoutGaps:  0.2rem, 0.25rem, 0.5em, 0.5rem, 0.625rem, 0.675rem, 0.688rem, 0.75rem, 0.75em, 0.875rem, 0.9375rem, 
 1rem, 1em, 1.18rem, 1.25rem, 1.313rem, 1.375rem, 1.38rem, 1.5em, 1.5rem, 1.625rem, 1.875rem, 2rem, 2em, 2.06rem, 2.25rem, 2.375rem, 2.688rem, 2.69rem,
 2.75rem, 3rem, 3em, 3.188rem, 3.25rem,  3.5625rem, 3.938rem, 4.5rem, 5rem, 6em, 5, 10, 15, 20, 25, 4px, 5px, 8px, 10px, 12px, 15px, 20px, 
 22px, 24px, 32px, 40px, 62px, 30px, 5px 5px, 25px 25px, 'unset';

// fxLayout="XX"
$flexLayoutTypes: row, column, row wrap;

// fxLayoutAlign="XX"
$flexLayoutAlignTypes: space-between center, start center, center center, start left, start stretch, 
start baseline, space-around center, space-around stretch, space-between stretch, end center, center,
start start, end end, start end, end start, center start, space-evenly center, space-between start,
start space-between, end stretch, space-between top, flex-start center, flex-end center, flex-end flex-start, space-between ;

// ================================================================================================

[fxFlex] {
    flex: 1 1 0%;
    box-sizing: border-box;
    flex-direction: row;
    display: flex;
}

[fxLayoutAlign] {
    display: flex;
    box-sizing: border-box;
}

// fxFlex="XX"      
@each $flexWidth in $flexWidths {
    @if (type-of($flexWidth) == 'number') {
		[fxFlex="#{$flexWidth}"] {
			flex: 1 1 100%;
			max-width: $flexWidth *1%;
		}
	} @else {
		[fxFlex="#{$flexWidth}"] {
			flex: 1 1 100%;
			max-width: $flexWidth;
		}
	}
}
// fxLayoutGap="XX"
@each $flexLayoutGap in $flexLayoutGaps {
    [fxLayoutGap="#{$flexLayoutGap}"] {
        gap:$flexLayoutGap;
    }
}
// fxLayout="XX"
@each $flexDirection, $flexWrap in $flexLayoutTypes {
    @if ($flexWrap) {
        [fxLayout="#{$flexDirection} #{$flexWrap}"] {
            display: flex;
            flex-wrap: $flexWrap;
            flex-direction: $flexDirection !important;
        }
    } @else {
        [fxLayout="#{$flexDirection}"] {
            display: flex;
            flex-direction: $flexDirection !important;
        }
    }
}

//fxLayoutAlign="XX"
@each $justifyContent, $alignItems in $flexLayoutAlignTypes {
    @if ($alignItems) {
        [fxLayoutAlign="#{$justifyContent} #{$alignItems}"] {
            place-content: $alignItems $justifyContent;
            align-items: $alignItems;
        }        
    } @else {
        [fxLayoutAlign="#{$justifyContent}"] {
            place-content: $justifyContent $justifyContent;
            align-items: $justifyContent;
        }
    }
}

// fxHide
[fxHide] {
    display: none;
}
// fxShow
[fxShow] {
    display: flex;
}
// fxFill
[fxFlexFill], [fxFill] {
    height: 100%;
    min-height: 100%;
    min-width: 100%;
    width: 100%;
    display: flex;
}

//import breakpoint specific CSS at the end so the breakpoint specific css takes the precedence
@import './flex-layout-break-points.scss';
